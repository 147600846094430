import {createTheme, responsiveFontSizes} from "@mui/material";
import {ACCENT_COLOR, bg, BG_COLOR, PRIMARY_COLOR} from "../configuration";

export const customTheme = responsiveFontSizes(createTheme({
  components: {
      MuiAppBar: {
          styleOverrides:{
              root:{
                  background: 'transparent',
                  borderBottom: '2px solid #151a37',
                  borderRadius:'0'
              }
          }
      },
      MuiPaper: {
          variants: [
              {
                  props: {variant: 'domanda'},
                  style: {
                      borderRadius: '1rem',
                      background: 'rgba(147,147,147,0.0)',
                      //border: '0.1px solid white',
                  }
              }
          ]
      },
      MuiTabPanel: {
          styleOverrides:{
              root:{
                  padding: '1rem 0.6rem'
              }
          }
      },
      MuiButton: {
          styleOverrides: {
              root: {
                  color: '#fff',
                  backgroundColor: '#00347a',
                  '&:hover': {
                      backgroundColor: '#002a5c', // Scurisce leggermente il colore al passaggio del mouse
                  },
              },
          },
      },
      MuiTextField: {
          styleOverrides: {
              root: {
                  '& .MuiInputBase-root': {
                      color: '#00347a',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#00347a',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#002a5c',
                  },
                  '& .MuiInputLabel-root': {
                      color: '#00347a',
                  },
              },
          },
      },
      MuiSelect: {
          styleOverrides: {
              root: {
                  color: '#00347a',
                  '& .MuiSelect-icon': {
                      color: '#00347a', // Colore dell'icona del dropdown
                  },
              },
          },
      },
      MuiMenuItem: {
          styleOverrides: {
              root: {
                  color: '#fff',
                  '&:hover': {
                      backgroundColor: '#002a5c',
                      color: '#ffffff',
                  },
              },
          },
      },
      MuiInputAdornment: {
          styleOverrides: {
              root: {
                  color: '#00347a', // Colore per l'icona nell'adornment
              },
          },
      },
  },
    palette:{
      mode:'dark',
        primary:{
          main:PRIMARY_COLOR
        },
        background:{
          default:BG_COLOR,
        },
        secondary:{
          main:ACCENT_COLOR
        },
        error:{
          main:'#fde451'
        },
        warning:{
          main:'#fde451'
        },
        success:{
          main:'#79d8a1'
        }
    }
}))
