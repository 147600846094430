import React, {useEffect, useState} from "react";
import {Box, InputAdornment, MenuItem, Select} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Language} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";

export const LangSwitcher = () => {
    const {t, i18n} = useTranslation()
    const [language, setLanguage] = useState('en')
    const handleChangeLanguage = (e) => {
        i18n.changeLanguage(e.target.value)
            .then(() => {})
    }

    useEffect(() =>{
        setLanguage(['it', 'en'].includes(i18n.language.split('-')[0]) ? i18n.language.split('-')[0] : 'en')
    },[i18n.language])

    return (
        <Select variant='standard' sx={{width: '5.5rem', color:'#00347a'}} value={language}
                onChange={handleChangeLanguage}
                startAdornment={<InputAdornment position="start"><Language color={'#00347a'}/></InputAdornment>}
        >
            <MenuItem value={'it'}>It</MenuItem>
            <MenuItem sx={{}} value={'en'}>En</MenuItem>
        </Select>
    )
}
